import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import Sidebar from "../components/Sidebar";
import DimensionCard from "../components/DimensionCard";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { QuestionMarkIcon } from "../assets/Icons";
import RadioButtons from "../components/RadioButtons";
import PercentageInput from "../components/PercentageInput";
import CustomProgressBar from "../components/ProgressBar";
import ProgressBar from "@ramonak/react-progress-bar";
import CheckboxList from "../components/CheckboxList";

export default function QuestionsPage() {
  const [error_message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dimension, setDimension] = useState(undefined);
  const [questions, setQuestions] = useState();
  const [round, setRound] = useState(undefined);
  const [userResponse, setUserResponse] = useState(undefined);
  const { idDimension } = useParams();
  const { idSubdimension } = useParams();
  const [index, setIndex] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const navigate = useNavigate();
  const today = new Date();

  const handleChange = (field, value) => {
    let obj = userResponse.slice();
    obj[index].answer = value;
    obj[index].answers = [parseInt(value)];
    console.log(obj);
    setUserResponse(obj);
    setPercentage(
      userResponse
        ? Math.round(
            (userResponse.filter(
              (element) => element.answers || element.customAnswer
            ).length /
              userResponse?.length) *
              100
          )
        : 0
    );
  };

  const handleMultipleChange = (field, value) => {
    let obj = userResponse.slice();

    // Asegurarse de que 'answers' sea un array de IDs
    obj[index].answers = obj[index].answers
      ? obj[index].answers.map((a) => (typeof a === "object" ? a.id : a))
      : [];

    const valueInt = parseInt(value);

    // Si el valor ya está en el array, lo elimina
    if (obj[index].answers.includes(valueInt)) {
      obj[index].answers = obj[index].answers.filter(
        (answer) => answer !== valueInt
      );
    } else {
      // Si no está, lo añade
      obj[index].answers = [...obj[index].answers, valueInt];
    }

    // Asigna el primer valor del array a 'answer' si existe al menos uno
    obj[index].answer =
      obj[index].answers.length > 0 ? obj[index].answers[0] : null;

    setUserResponse(obj);

    setPercentage(
      userResponse
        ? Math.round(
            (userResponse.filter(
              (element) => element.answers || element.customAnswer
            ).length /
              userResponse?.length) *
              100
          )
        : 0
    );
  };

  const handleCustomAnswer = (field, value) => {
    let obj = userResponse.slice();
    obj[index].customAnswer = value;
    delete obj[index].answers;
    console.log(obj);
    setUserResponse(obj);
    setPercentage(
      userResponse
        ? Math.round(
            (userResponse.filter(
              (element) => element.answers || element.customAnswer
            ).length /
              userResponse?.length) *
              100
          )
        : 0
    );
  };

  const goNext = () => {
    if (index < questions.length - 1) {
      setIndex(index + 1);
    }
  };

  const goPrev = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    console.log(userResponse);
  }, [userResponse]);

  useEffect(() => {
    if (idDimension)
      api.dimensions
        .getOne(idDimension)
        .then((response) => {
          setDimension(response);
          api.questions
            .getMulti(null, null, null, null, {
              dimension: idDimension,
              subdimension: idSubdimension,
            })
            .then((result) => {
              setQuestions(result);
              api.users.getMe().then((userData) => {
                api.rounds
                  .getMulti(null, null, null, null, {
                    dimension: idDimension,
                    company: userData.company,
                  })
                  .then((round) => {
                    if (round.length) {
                      setRound(round[round.length - 1]);
                      api.userResponses
                        .getMulti(null, null, null, null, {
                          round: round[round.length - 1].id,
                        })
                        .then((array) => {
                          if (array.length === result.length) {
                            setUserResponse(
                              result.map((element) => ({
                                ...array.filter(
                                  (answer) => element.id === answer.question.id
                                )[0],
                                /*answer: array.filter(
                                  (answer) => element.id === answer.question.id
                                )[0].answer,*/
                              }))
                            );
                            setPercentage(
                              Math.round(
                                (array.filter(
                                  (element) =>
                                    element.answers || element.customAnswer
                                ).length /
                                  result.length) *
                                  100
                              )
                            );
                            setLoading(false);
                          } else if (array.length) {
                            let aux = result
                              .filter((element) =>
                                array.find(
                                  (obj) => obj.question.id === element.id
                                )
                              )
                              .map((element) => ({
                                ...array.filter(
                                  (answer) => element.id === answer.question.id
                                )[0],
                                /*answer: array.filter(
                                  (answer) => element.id === answer.question.id
                                )[0].answer,*/
                              }));
                            result
                              .filter(
                                (element) =>
                                  !array.find(
                                    (obj) => obj.question.id === element.id
                                  )
                              )
                              .forEach((element) =>
                                aux.push({
                                  round: round[round.length - 1].id,
                                  question: element.id,
                                })
                              );
                            setUserResponse(aux);
                            setPercentage(
                              Math.round(
                                (array.filter(
                                  (element) =>
                                    element.answers || element.customAnswer
                                ).length /
                                  result.length) *
                                  100
                              )
                            );
                            setLoading(false);
                          } else {
                            setUserResponse(
                              result.map((element) => ({
                                round: round[round.length - 1].id,
                                question: element.id,
                              }))
                            );
                            setLoading(false);
                          }
                        });
                    } else {
                      api.rounds
                        .create({
                          company: userData.company,
                          dimension: idDimension,
                        })
                        .then((round) => {
                          setRound(round);
                          setUserResponse(
                            result.map((element) => ({
                              round: round.id,
                              question: element.id,
                            }))
                          );
                          setLoading(false);
                        })
                        .catch((error) => console.log(error));
                    }
                  })
                  .catch((error) => console.log(error));
              });
            });
        })
        .catch((error) => setErrorMessage(error));
  }, [idDimension]);

  const handleSubmit = () => {
    setErrorMessage(undefined);
    setLoading(true);
    let aux = 0;
    for (var answer in userResponse) {
      console.log(userResponse[answer]);
      if (userResponse[answer].id) {
        console.log("update");
        //const answerIds = [...userResponse[answer].answers].map((a) => a.id);
        aux++;
        api.userResponses
          .update(userResponse[answer].id, {
            ...userResponse[answer],
            round: userResponse[answer].round.id,
            question: userResponse[answer].question.id,
          })
          .then(() => {
            if (
              aux ===
              userResponse.filter(
                (element) => element.answers || element.customAnswer
              ).length
            )
              navigate("/diagnoses");
          })
          .catch((error) => (setErrorMessage(error), navigate("/diagnoses")));
      } else if (
        userResponse[answer].answers ||
        userResponse[answer].customAnswer
      ) {
        console.log("create");
        aux++;
        api.userResponses
          .create(userResponse[answer])
          .then(() => {
            if (
              aux ===
              userResponse.filter(
                (element) => element.answers || element.customAnswer
              ).length
            )
              navigate("/diagnoses");
          })
          .catch((error) => setErrorMessage(error), navigate("/diagnoses"));
      }
    }
  };

  return (
    <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
      <Sidebar
        currentScreen={"Diagnosticar"}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className="md:pl-64 min-h-screen w-full flex flex-col pb-10 relative">
        {loading ? (
          <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
        ) : (
          <>
            <CustomProgressBar />
            <div className="mt-6 px-6 flex flex-col">
              <div className="flex justify-between">
                <p className="text-2xl font-bold">Diagnosticar</p>
                {!idSubdimension && (
                  <div className="w-72 flex gap-3 mr-6">
                    <div className="w-full my-auto">
                      <ProgressBar
                        bgColor="#4DD6B6"
                        completed={percentage}
                        isLabelVisible={false}
                      />
                    </div>
                    <p className="text-[#5C5C5C] my-auto">{`${
                      userResponse
                        ? userResponse.filter(
                            (element) => element.answers || element.customAnswer
                          ).length
                        : "0"
                    }/${userResponse?.length ? userResponse?.length : "0"}`}</p>
                  </div>
                )}
              </div>
              <p className="text-[#BABABA] text-sm mt-2">
                Contestá una serie de preguntas y recibí un informe sobre el
                estado de situación de tu empresa en relación con las políticas
                de cuidado.
              </p>
              <div className="border border-[#D9D9D9] w-full mt-3" />
            </div>
            <div className="flex flex-col justify-between h-full mx-auto text-center">
              <div
                onClick={() => goPrev()}
                className={classNames(
                  index === 0 ? "invisible" : "",
                  "flex flex-col text-[#9CA3AF] mx-auto cursor-pointer"
                )}
              >
                <ChevronUpIcon className="w-11 h-11 mx-auto" />
                <p>Pregunta anterior</p>
              </div>
              <div className="flex flex-col gap-3 mx-auto">
                <div className="w-fit h-fit px-8 py-6 mx-auto bg-[#C2E3F8] rounded-full">
                  <QuestionMarkIcon />
                </div>
                <p className="text-2xl px-12 leading-8 font-semibold">
                  {questions && questions[index]?.text}
                </p>
                {questions[index]?.observations && (
                  <p className="text-sm px-12 leading-8 text-[#BABABA] font-semibold">
                    {questions[index]?.observations}
                  </p>
                )}
                {questions[index].answers.length > 0 &&
                !questions[index].isMultipleChoice ? (
                  <RadioButtons
                    customAnswer={
                      userResponse?.customAnswer
                        ? userResponse[index]?.customAnswer
                        : null
                    }
                    defaultValue={
                      userResponse && userResponse[index]?.answers
                        ? userResponse[index]?.answers[0]?.id
                          ? userResponse[index]?.answers[0]?.id
                          : userResponse[index]?.answers
                          ? userResponse[index]?.answer
                          : null
                        : null
                    }
                    handleChange={handleChange}
                    handleCustomAnswer={handleCustomAnswer}
                    name="answer"
                    options={questions[index].answers.map((element) => ({
                      id: element.id,
                      default: false,
                      label: element.text,
                      value: element.id,
                    }))}
                  />
                ) : questions[index].answers.length > 0 &&
                  questions[index].isMultipleChoice ? (
                  <CheckboxList
                    customAnswer={
                      userResponse?.customAnswer
                        ? userResponse[index]?.customAnswer
                        : null
                    }
                    defaultValue={
                      userResponse && userResponse[index]?.answers
                        ? userResponse[index]?.answers.map(
                            (answer) => answer.id || answer
                          )
                        : []
                    }
                    handleChange={handleMultipleChange}
                    handleCustomAnswer={handleCustomAnswer}
                    name="answer"
                    options={questions[index].answers.map((element) => ({
                      id: element.id,
                      default: false,
                      label: element.text,
                      value: element.id,
                    }))}
                  />
                ) : (
                  <div className="flex flex-row items-center mx-auto">
                    <PercentageInput
                      description="Mujeres"
                      value={
                        userResponse
                          ? userResponse[index]?.customAnswer
                            ? userResponse[index]?.customAnswer
                            : userResponse[index]?.answers
                            ? userResponse[index]?.answers[0]?.text
                            : ""
                          : ""
                      }
                      handleChange={handleCustomAnswer}
                      name="customAnswer"
                    />
                    {index === 1 && (
                      <p className="text-primary font-semibold mt-4 text-xl text-left">
                        {userResponse[index]?.customAnswer
                          ? 100 - userResponse[index]?.customAnswer
                          : userResponse[index]?.answers
                          ? 100 - userResponse[index]?.answers[0]?.text
                          : ""}{" "}
                        % Hombres
                      </p>
                    )}
                  </div>
                )}
                {/*questions[index].isGenderPercentage && (
                  <PercentageInput
                    description="Hombres"
                    value={
                      userResponse[index]?.answers?.text
                        ? 100 - parseInt(userResponse[index]?.answers.text)
                        : userResponse[index]?.customAnswer
                        ? 100 - userResponse[index]?.customAnswer
                        : ""
                    }
                    name="customAnswer"
                    disabled={true}
                  />
                )*/}
              </div>
              <div
                onClick={() => goNext()}
                className={classNames(
                  index === questions?.length - 1 ? "invisible" : "",
                  "flex flex-col text-[#9CA3AF] mx-auto cursor-pointer"
                )}
              >
                <ChevronDownIcon className="w-11 h-11 mx-auto" />
                <p>Siguiente pregunta </p>
              </div>
            </div>
            {userResponse?.some(
              (element) => element.answers?.length > 0 || element.customAnswer
            ) && (
              <button
                onClick={() => handleSubmit()}
                className="py-1 px-12 border border-[#2095DF] text-[#2095DF] font-semibold align-middle rounded-2xl bg-white w-fit absolute right-20 bottom-10"
              >
                Finalizar y guardar
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
