import React, { useEffect, useState } from "react";
import api from "../api";
import ProgressBar from "@ramonak/react-progress-bar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CustomProgressBar() {
  const [error_message, setErrorMessage] = useState("");
  const [dimensions, setDimensions] = useState(undefined);
  const [answers, setAnswers] = useState(undefined);
  const [questions, setQuestions] = useState(undefined);
  const today = new Date();

  useEffect(() => {
    api.dimensions
      .getMulti()
      .then((response) => {
        setDimensions(response);
        api.users.getMe().then((userData) => {
          if (userData.company)
            api.rounds
              .getMulti(null, null, null, null, { company: userData.company })
              .then((roundsResult) => {
                api.userResponses
                  .getMulti(null, null, null, null, {
                    company: userData.company,
                  })
                  .then((result) => {
                    let auxA = roundsResult.filter(
                      (element) => element.status !== 3
                    );
                    let auxB = roundsResult.filter(
                      (element) =>
                        !auxA.find(
                          (auxElement) =>
                            auxElement.dimension === element.dimension
                        )
                    );
                    let selectedRounds = [...auxA, ...auxB];
                    let finalRounds = [];
                    selectedRounds.forEach((element) => {
                      if (
                        finalRounds.findIndex(
                          (obj) => obj.dimension === element.dimension
                        ) !== -1
                      )
                        finalRounds[
                          finalRounds.findIndex(
                            (obj) => obj.dimension === element.dimension
                          )
                        ] = element;
                      else finalRounds.push(element);
                    });
                    setAnswers(
                      result.filter((element) =>
                        finalRounds.find(
                          (roundElement) => roundElement.id === element.round.id
                        )
                      )
                    );
                    api.questions
                      .getMulti()
                      .then((array) => {
                        setQuestions(array);
                      })
                      .catch((error) => console.log(error));
                  });
              });
        });
      })
      .catch((error) => setErrorMessage(error));
  }, []);

  useEffect(() => {
    if (answers)
      console.log(
        Math.round(
          answers.reduce(
            (sum, answer) => sum + answer.answers[0]?.percentage,
            0
          ) / 3
        )
      );
  }, [answers]);

  return (
    <div className="bg-white w-full h-fit px-4 py-2 flex justify-end gap-6 border-b border-b-gray-200">
      <div className="flex flex-col gap-2 text-[#5C5C5C]">
        <p className="font-semibold">Progreso actual</p>
        <p>{today.toISOString().split("T")[0]}</p>
      </div>
      <div className="w-72 my-auto">
        <ProgressBar
          bgColor="#4DD6B6"
          labelColor="#5C5C5C"
          labelAlignment="outside"
          completed={
            answers?.lenght > 0
              ? Math.round(
                  answers.reduce(
                    (sum, answer) => sum + answer.answers[0]?.percentage,
                    0
                  ) / 3
                )
              : 0
          }
        />
      </div>
    </div>
  );
}
