import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import Sidebar from "../components/Sidebar";
import DimensionCard from "../components/DimensionCard";
import CustomProgressBar from "../components/ProgressBar";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "../components/Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function DiagnosisPage() {
  const [error_message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dimensions, setDimensions] = useState(undefined);
  const [userInfo, setUserInfo] = useState(undefined);
  const [rounds, setRounds] = useState(undefined);
  const [answers, setAnswers] = useState(undefined);
  const [questions, setQuestions] = useState(undefined);
  const navigate = useNavigate();
  const today = new Date();

  const colors = ["#CE73F7", "#9EDFCE", "#2095DF"];
  const bgColors = ["#CE73F721", "#E7F8F4", "#D9E9F0"];

  useEffect(() => {
    api.dimensions
      .getMulti()
      .then((response) => {
        setDimensions(response);
        api.users.getMe().then((userData) => {
          setUserInfo(userData);
          if (userData.company)
            api.rounds
              .getMulti(null, null, null, null, { company: userData.company })
              .then((roundsResult) => {
                setRounds(roundsResult);
                api.userResponses
                  .getMulti(null, null, null, null, {
                    company: userData.company,
                  })
                  .then((result) => {
                    let auxA = roundsResult.filter(
                      (element) => element.status !== 3
                    );
                    let auxB = roundsResult.filter(
                      (element) =>
                        !auxA.find(
                          (auxElement) =>
                            auxElement.dimension === element.dimension
                        )
                    );
                    let selectedRounds = [...auxA, ...auxB];
                    let finalRounds = [];
                    selectedRounds.forEach((element) => {
                      if (
                        finalRounds.findIndex(
                          (obj) => obj.dimension === element.dimension
                        ) !== -1
                      )
                        finalRounds[
                          finalRounds.findIndex(
                            (obj) => obj.dimension === element.dimension
                          )
                        ] = element;
                      else finalRounds.push(element);
                    });
                    setAnswers(
                      result.filter((element) =>
                        finalRounds.find(
                          (roundElement) => roundElement.id === element.round.id
                        )
                      )
                    );
                    api.questions
                      .getMulti()
                      .then((array) => {
                        setQuestions(array);
                        setLoading(false);
                      })
                      .catch((error) => console.log(error));
                  });
              });
          else {
            setLoading(false);
            setShowModal(true);
          }
        });
      })
      .catch((error) => setErrorMessage(error));
  }, []);

  const warningModal = (
    <>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-yellow-800"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Aviso
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Antes de poder acceder a esta sección debe registrar los datos de
              su empresa
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
          onClick={() => {
            navigate("/company-profile");
          }}
        >
          Aceptar
        </button>
      </div>
    </>
  );

  return (
    <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
      <Sidebar
        currentScreen={"Diagnosticar"}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Modal
        size="sm:max-w-lg w-full"
        content={warningModal}
        open={showModal}
        setOpen={setShowModal}
      />
      <div className="md:pl-64 min-h-screen w-full flex flex-col pb-10">
        {loading ? (
          <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
        ) : (
          <>
            <CustomProgressBar />
            <div className="mt-6 px-6 flex flex-col">
              <p className="text-2xl font-bold">Diagnosticar</p>
              <p className="text-[#5E5E5E] text-sm mt-2">
                Completá el autodiagnóstico y recibí un informe sobre la
                situación de tu empresa en relación con las buenas prácticas de
                cuidado. Implementá acciones de mejora y repetí el
                autodiagnóstico para seguir avanzando y mejorar tus indicadores.
              </p>
              <div className="border border-[#D9D9D9] w-full mt-3" />
            </div>
            <div className="mt-10 px-6 flex flex-col gap-3">
              <p className="font-semibold">Información general</p>
              {dimensions &&
                dimensions
                  .filter((element) =>
                    element.name.toLowerCase().includes("empresa")
                  )
                  .map((element) => (
                    <DimensionCard
                      idCompany={userInfo?.company}
                      key={element.id}
                      idDimension={element.id}
                      title={element.name}
                      description="Características generales"
                      totalQuestions={
                        questions
                          ? questions.filter(
                              (question) => question.dimension === element.id
                            ).length
                          : 0
                      }
                      answeredQuestions={
                        answers
                          ? answers.filter(
                              (answer) =>
                                answer.answers &&
                                answer.round.dimension === element.id
                            ).length
                          : 0
                      }
                    />
                  ))}
            </div>
            <div className="mt-10 px-6 flex flex-col gap-3">
              <p className="font-semibold">Dimensiones</p>
              <div className="grid grid-cols-3 pr-4">
                {dimensions &&
                  dimensions
                    .filter(
                      (element) =>
                        !element.name.toLowerCase().includes("empresa")
                    )
                    .map((element, index) => (
                      <DimensionCard
                        idCompany={userInfo?.company}
                        key={element.id}
                        idDimension={element.id}
                        index={index + 1}
                        title={element.name}
                        color={bgColors[index % 3]}
                        borderColor={colors[index % 3]}
                        textColor={colors[index % 3]}
                        description={element.description}
                        totalQuestions={
                          questions
                            ? questions.filter(
                                (question) => question.dimension === element.id
                              ).length
                            : 0
                        }
                        answeredQuestions={
                          answers
                            ? answers.filter(
                                (answer) =>
                                  answer.answers &&
                                  answer.round.dimension === element.id
                              ).length
                            : 0
                        }
                      />
                    ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
