import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import Sidebar from "../components/Sidebar";
import CustomProgressBar from "../components/ProgressBar";
import RecordCard from "../components/RecordCard";
import BarChart from "../components/BarChart";

export default function RecordPage() {
  const [error_message, setErrorMessage] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dimensions, setDimensions] = useState(undefined);
  const [results, setResults] = useState(undefined);

  const navigate = useNavigate();

  const colors = ["#CE73F7", "#9EDFCE", "#2095DF"];

  useEffect(() => {
    api.dimensions
      .getMulti()
      .then((response) => {
        setDimensions(response);
        api.users.getMe().then((userData) => {
          api.userResponses.getHistory(userData.company).then((result) => {
            setResults(result);
          });
        });
      })
      .catch((error) => setErrorMessage(error));
  }, []);

  return (
    <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
      <Sidebar
        currentScreen={"Progreso"}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className="md:pl-64 min-h-screen w-full flex flex-col pb-10">
        <CustomProgressBar />
        <div className="mt-6 px-6 flex flex-col">
          <div className="flex justify-between">
            <p className="text-2xl font-bold">Progreso</p>
            <p
              onClick={() => navigate("/progress")}
              className="text-primary font-medium self-end underline cursor-pointer"
            >
              Ver puntaje actual
            </p>
          </div>
          <p className="text-[#BABABA] text-sm mt-2">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien.
          </p>
          <div className="border border-[#D9D9D9] w-full mt-3" />
        </div>
        <div className="mt-10 px-6 flex flex-col gap-3">
          <p className="mx-auto font-semibold text-[#5C5C5C] text-xl">
            Puntajes anteriores por dimensión
          </p>
          {results &&
            results.map((element, idx) => (
              <>
                <p className="font-semibold" style={{ color: colors[idx % 3] }}>
                  {element.dimension.name}
                </p>
                <div className="grid grid-cols-3 gap-6">
                  {element.rounds.map((round, idxRound) => (
                    <RecordCard
                      index={idxRound}
                      percentage={Math.round(round.total)}
                      color={colors[idx % 3]}
                    />
                  ))}
                </div>
              </>
            ))}
          {results && (
            <div className="md:w-3/4 md:h-[500px] ml-auto mr-auto">
              <BarChart />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
