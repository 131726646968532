import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import Sidebar from "../components/Sidebar";
import DimensionGauge from "../components/DimensionGauge";
import CustomProgressBar from "../components/ProgressBar";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "../components/Modal";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export default function ProgressPage() {
  const [error_message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dimensions, setDimensions] = useState(undefined);
  const [answers, setAnswers] = useState(undefined);
  const [rounds, setRounds] = useState(undefined);
  const [questions, setQuestions] = useState(undefined);

  const [max, setMax] = useState({});
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [attempt, setAttempt] = useState("");
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const navigate = useNavigate();

  const colors = ["#CE73F7", "#9EDFCE", "#2095DF"];

  useEffect(() => {
    api.dimensions
      .getMulti()
      .then((response) => {
        setDimensions(response);
        api.users.getMe().then((userData) => {
          if (userData.company)
            api.rounds
              .getMulti(null, null, null, null, { company: userData.company })
              .then((roundsResult) => {
                setRounds(roundsResult);
                api.userResponses
                  .getMulti(null, null, null, null, {
                    company: userData.company,
                  })
                  .then((result) => {
                    let auxA = roundsResult.filter(
                      (element) => element.status !== 3
                    );
                    let auxB = roundsResult.filter(
                      (element) =>
                        !auxA.find(
                          (auxElement) =>
                            auxElement.dimension === element.dimension
                        )
                    );
                    let selectedRounds = [...auxA, ...auxB];
                    let finalRounds = [];
                    selectedRounds.forEach((element) => {
                      if (
                        finalRounds.findIndex(
                          (obj) => obj.dimension === element.dimension
                        ) !== -1
                      )
                        finalRounds[
                          finalRounds.findIndex(
                            (obj) => obj.dimension === element.dimension
                          )
                        ] = element;
                      else finalRounds.push(element);
                    });
                    setAnswers(
                      result.filter((element) =>
                        finalRounds.find(
                          (roundElement) => roundElement.id === element.round.id
                        )
                      )
                    );
                    api.questions
                      .getMulti()
                      .then((array) => {
                        setQuestions(array);
                        setLoading(false);
                      })
                      .catch((error) => console.log(error));
                  });
              });
          else {
            setLoading(false);
            setShowModal(true);
          }
        });
      })
      .catch((error) => setErrorMessage(error));
  }, []);

  useEffect(() => {
    api.rounds.getScore().then((result) => {
      const maxIntentos = Math.max(
        ...Object.values(result).map((arr) => arr.length)
      );
      setMax(maxIntentos);
    });
  }, []);

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    setAttempt(value);

    // Validación del rango
    if (value > max || value < 1) {
      // Asumiendo que 1 es el mínimo permitido
      setFieldErrors({ attempt: ["El número debe estar entre 1 y " + max] });
    } else {
      setFieldErrors({ attempt: [] }); // Resetea los errores si es válido
    }
  };

  const warningModal = (
    <>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-yellow-800"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Aviso
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Antes de poder acceder a esta sección debe registrar los datos de
              su empresa
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
          onClick={() => {
            navigate("/company-profile");
          }}
        >
          Aceptar
        </button>
      </div>
    </>
  );

  const handleFormSubmit = async (event) => {
    if (!attempt || (attempt && attempt >= 1 && attempt <= max)) {
      event.preventDefault();
      setError(null);
      setFieldErrors({});

      const data = {
        attempt: +attempt,
      };

      try {
        const response = await api.rounds.download(data);
        console.log("Respuesta del servidor:", response);

        // Validar datos
        if (!response) {
          throw new Error("La respuesta no contiene datos para descargar.");
        }

        // Crear el archivo y descargarlo
        const blob = new Blob([response], {
          type:
            response.type ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Usar un nombre de archivo predeterminado
        const fileName = "reporte.xlsx";

        // Crear un enlace para descargar el archivo
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Liberar memoria
        URL.revokeObjectURL(link.href);
        // Reinicia los valores del formulario
        setAttempt("");
        setShowDownloadModal(false);
      } catch (error) {
        if (error.message) {
          setFieldErrors(error.message); // Ajustar esto según la estructura del error
        } else {
          setError("Ocurrió un error al enviar los datos.");
        }
      }
    } else {
      return;
    }
  };

  const downloadModal = (
    <>
      <div className="flex flex-col">
        <XMarkIcon
          onClick={() => setShowDownloadModal(false)}
          className="w-6 h-6 self-end cursor-pointer"
        />
        <div className="mt-3 text-center sm:mt-5 space-y-6">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Descargar respuestas
          </Dialog.Title>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-yellow-800"
              aria-hidden="true"
            />
          </div>
          <p className="font-medium text-sm text-[#1E40AF]">
            Deja el campo vacío si quieres descargar el informe completo
          </p>
          <div>
            <input
              type="number"
              placeholder="Ingresa el número de intento"
              value={attempt}
              onChange={handleInputChange}
              className="text-xs bg-[#F9FAFB] w-full rounded-md py-4 px-[23px] focus:outline-none focus:ring"
              max={max}
            />
            {fieldErrors.attempt && (
              <p className="mt-2 text-sm text-red-600">
                {fieldErrors.attempt[0]}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
          onClick={handleFormSubmit}
        >
          Descargar
        </button>
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </>
  );

  return (
    <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
      <Sidebar
        currentScreen={"Progreso"}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Modal
        size="sm:max-w-lg w-full"
        content={warningModal}
        open={showModal}
        setOpen={setShowModal}
      />
      <Modal
        size="sm:max-w-lg w-full"
        content={downloadModal}
        open={showDownloadModal}
        setOpen={setShowDownloadModal}
      />
      <div className="md:pl-64 min-h-screen w-full flex flex-col pb-10">
        <CustomProgressBar />
        <div className="mt-6 px-6 flex flex-col">
          <div className="flex justify-between">
            <p className="text-2xl font-bold">Progreso</p>
            <p
              onClick={() => navigate("/history")}
              className="text-primary font-medium self-end underline cursor-pointer"
            >
              Ver puntajes anteriores
            </p>
          </div>
          <p className="text-[#5E5E5E] text-sm mt-2">
            Accedé a un resumen visual de tus respuestas y observá el avance de
            tu empresa en la implementación de buenas prácticas de cuidado.
            Monitoreá tu progreso a lo largo del tiempo y evaluá el impacto de
            las mejoras implementadas.{" "}
          </p>
          <div className="border border-[#D9D9D9] w-full mt-3" />
        </div>
        <div className="relative mt-4 px-6 flex flex-col gap-3">
          <p className="mx-auto font-semibold text-[#5C5C5C] text-xl">
            Resumen por dimensión
          </p>
          <button
            type="button"
            className="absolute right-[24px] top-[-5px] inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
            onClick={() => {
              setShowDownloadModal(true);
            }}
          >
            Descargar respuestas
          </button>
          <div className="grid grid-cols-3 gap-6">
            {dimensions &&
              dimensions
                .filter(
                  (element) => !element.name.toLowerCase().includes("empresa")
                )
                .map((element, index) => (
                  <DimensionGauge
                    key={element.id}
                    percentage={
                      answers?.length > 0
                        ? Math.round(
                            answers
                              .filter(
                                (answer) =>
                                  answer.round.dimension === element.id
                              )
                              .reduce(
                                (sum, answer) =>
                                  sum + answer.answers[0]?.percentage,
                                0
                              )
                          )
                        : 0
                    }
                    title={element.name}
                    subtitle={"¡Buen trabajo!"}
                    index={index + 1}
                    description="La empresa cuenta con importantes avances en políticas de compromiso, comunicación y difusión de políticas de cuidado, pero aún existen desafíos invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender."
                    color={colors[index % 3]}
                  />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}
