import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import api from "../api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const [scores, setScores] = useState({});

  useEffect(() => {
    api.rounds.getScore().then((result) => {
      setScores(result);
      console.log(result);
    });
  }, []);

  // Generar las dimensiones (etiquetas del eje X)
  const dimensiones = Object.keys(scores);

  // Crear datos para cada intento
  const maxIntentos = Math.max(
    ...Object.values(scores).map((arr) => arr.length)
  );
  const colores = ["#FF6384", "#36A2EB", "#FFCE56", "#8E44AD", "#27AE60"]; // Colores adicionales si hay más intentos

  // Crear datasets a partir de los datos de la API
  const datasets = Array.from({ length: maxIntentos }).map(
    (_, intentoIndex) => ({
      label: `Intento ${intentoIndex + 1}`,
      data: dimensiones.map((dim) => scores[dim]?.[intentoIndex] || 0), // Puntaje de cada dimensión para este intento
      backgroundColor: colores[intentoIndex % colores.length], // Color del intento
    })
  );

  // Configuración de Chart.js
  const data = {
    labels: dimensiones,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Puntajes por Dimensión e Intento",
        font: {
          size: 20,
          weight: "600",
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
